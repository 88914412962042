import { Link } from 'gatsby'
import React from 'react'
import ArrowRight from '../../../images/icons/arrow-right.svg'
import MaxProfitImageOne from '../../../images/maximizing-profit/max-profit-1.png'
import MaxProfitImageTwo from '../../../images/maximizing-profit/max-profit-2.png'
import MaxProfitImageThree from '../../../images/maximizing-profit/max-profit-3.png'

const MaximizingProfit = () => {
    return (
        <div className="bg-maximize-two pt-5 pb-md-5">

            <div className="row pt-5 pb-md-5">
                <div className="col-md-5">
                    <h2 className="mb-5 font-weight-bold" style={{ lineHeight: '40px' }}>Finding hidden opportunities. Maximizing profit drivers.</h2>

                    <p className="mb-3 text-secondary small" style={{ lineHeight: '26px', fontSize: '15px' }}>In the business landscape right now, transformation is the keyword for any business that wants to stand the test of time.</p>

                    <p className="mb-3 text-secondary small" style={{ lineHeight: '26px', fontSize: '15px' }}>We would love to see your business ace that test. Let us work with you to transform your business processes, realign your operations and offerings to stay on top of the shifting market demands, and navigate the complexities of the business world.</p>

                    <div className="mt-4 d-inline-flex mb-md-0 mb-5">
                        <Link to={`/contact`} state={{ path: 'transformation' }}><button className="btn btn-red text-uppercase btn-red-black btn-small px-3 mr-4 no-border-radius" style={{ fontSize: '12px' }}>start now</button></Link>
                        <Link className="my-auto" to="/transformation-usecase"><p className="small cursor black-link-text mb-0 my-auto">Learn more <img className="ml-3" src={ArrowRight} alt="arrow right" style={{ maxWidth: '6px' }} /></p></Link>
                    </div>
                </div>

                <div className="col-md-1"></div>

                <div className="col-md-6 d-md-block d-none">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="row">
                                <div className="col-md-6 mb-md-0 mb-4">

                                    <img src={MaxProfitImageOne} alt="maximize profit" className="img-fluid" style={{ objectFit: 'cover', width: '100%', height: '156px' }} />
                                </div>

                                <div className="col-md-6">
                                    <img src={MaxProfitImageTwo} alt="maximize profit" className="img-fluid" style={{ objectFit: 'cover', width: '100%', height: '156px' }} />
                                </div>
                            </div>
                        </div>


                        <div className="col-md-12 mt-4">
                            <img src={MaxProfitImageThree} alt="maximize profit" className="img-fluid" style={{ objectFit: 'cover', width: '100%', height: '156px' }} />
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default MaximizingProfit
