import React from 'react'
import HomeData from '../../../data/home-data'
import ButterflyImage from '../../../images/process-transformation/butterfly.png'
import StickyImage from '../../../images/process-transformation/sticky.png'
import PeopleChartImage from '../../../images/process-transformation/people-chart.png'
import ArrowRight from '../../../images/icons/arrow-right.svg'
import { Link } from 'gatsby'

const ProcessTransformation = () => {
    return (
        <div className="bg-process-transformation pt-5 pb-md-5">
            <div className="row pt-5 pb-md-5">
                <div className="col-md-5">
                    <h2 className="mb-5 font-weight-bold" style={{lineHeight: '40px'}}>{HomeData.process_transformation.title}</h2>

                    {HomeData.process_transformation.sub_title.map((data, index) => <p style={{ lineHeight: '26px', fontSize: '15px' }} className="mb-3 text-secondary small" key={index}>{data}</p>)}

                    <div className="mt-4 d-inline-flex mb-md-0 mb-5">
                        <Link to={`/contact`} state={{path: 'digital'}}><button className="btn btn-red text-uppercase btn-red-black btn-small px-3 mr-4 no-border-radius" style={{fontSize: '12px'}}>start now</button></Link>
                        <Link className="my-auto" to="/digital-usecase"><p className="small cursor black-link-text mb-0 my-auto">Learn more <img className="ml-3" src={ArrowRight} alt="arrow right" style={{maxWidth: '6px'}} /></p>  </Link>                
                    </div>
                </div>

                <div className="col-md-1"></div>

                <div className="col-md-6 mb-md-0 mb-4 d-md-block d-none">
                    <div className="row">
                        <div className="col-md-6  mb-md-0 mb-4">
                            <img src={ButterflyImage} alt="" className="img-fluid mb-4" />
                            <img src={StickyImage} alt="" className="img-fluid" />
                        </div>

                        <div className="col-md-6">
                            <img src={PeopleChartImage} alt="" className="img-fluid" style={{width: '100%', height: '100%', objectFit: 'cover'}}/>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default ProcessTransformation
