import React, { useEffect, useState } from "react"
import "./home.scss"
import ProcessTransformation from "./process-transformation/process-transformation"
import HomeData from "../../data/home-data"
import Partners from "./partners/partners"
import Team from "./team/team"
import BulbImage from "../../images/home/bulb.svg"
import Insight from "./insight/insight"
import Testimonial from "./testimonial/testimonial"
import NairaBg from "../../images/home/naira-bg.png"
import ArrowRight from "../../images/icons/arrow-right.svg"
import MaximizingProfit from "./maximizing-profit/maximizing-profit"
import SpeedPrecision from "./speed-precision/speed-precision"
import RiskAssessment from "./risk-assessment/risk-assessment"
import PeoplePower from "./people-power/people-power"
import TeamNumber from "../../images/icons/team.svg"
import Nav from "../../components/nav/nav"
import { Link } from "gatsby"
import { Modal } from "react-bootstrap"
import { FaTimesCircle } from "react-icons/fa";

import SalahImage from '../../images/celebrations/sallah.jpg'

const Home = () => {
  const [activeTab, setActiveTab] = useState("process")
  const [show, setShow] = useState(false)

  const changeTab = option => {
    setActiveTab(option)
  }

  return (
    <div className="home">
      {/* desktop hero */}
      <div className="hero-section d-md-block d-none">
        <Nav isWhite={false} />
        <div className="h-100 d-flex align-items-end my-md-0 my-5 py-md-0 py-5">
          <div className="container px-5 mb-md-5">
            <div className="row mb-5 pb-5 px-4">
              <div className="col-md-8">
                <div className="row">
                  <div className="col-md-1 col-5 d-md-block d-none px-0 mt-4">
                    <div style={{ borderBottom: "3px solid #ffffff" }}></div>
                  </div>
                  <div className="col-md-11">
                    <h1 className="text-white font-weight-bold hero-text">
                      Exceed your organizational goals with{" "}
                      <span className="text-red">world-class technology</span>
                    </h1>

                    <div className="d-inline-flex mt-4">
                      <Link to="/contact">
                        <button className="btn btn-red btn-small mr-4 text-uppercase btn-red-white px-4 no-border-radius">
                          start now
                        </button>
                      </Link>

                      <Link className="my-auto" to="/digital">
                        <p className="small cursor link-text mb-0 my-auto">
                          Learn more{" "}
                          <img
                            className="ml-3"
                            src={ArrowRight}
                            alt="arrow right"
                            style={{ maxWidth: "6px" }}
                          />
                        </p>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4 my-auto py-md-0 py-4">
                <p
                  className="off-white  mb-0"
                  style={{ lineHeight: "26px", fontSize: "15px" }}
                >
                  It is a popular belief that growing a business in this digital
                  age can be hard. That doesn’t have to be your truth. We are
                  here to change that notion by equipping your company with the
                  right answers to today’s business challenges, and to align
                  your business offerings with innovative technologies so that
                  you can do business in extraordinary ways.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* mobile */}
      <div className="hero-section d-md-none d-block">
        <Nav isWhite={false} />
        <div className="my-5 py-5">
          <div className="container px-5">
            <div className="row mb-5 pb-5 px-md-4 px-1">
              <div className="col-md-8">
                <div className="row">
                  <div className="col-md-1 col-5 d-md-block d-none px-0 mt-4">
                    <div style={{ borderBottom: "3px solid #ffffff" }}></div>
                  </div>
                  <div className="col-md-11">
                    <h3 className="text-white font-weight-bold">
                      Exceed your organizational goals with{" "}
                      <span className="text-red">world-class technology</span>
                    </h3>

                    <div className="d-md-inline-flex mt-4">
                      <Link to="/contact">
                        {" "}
                        <button className="btn btn-red btn-small mr-4 text-uppercase btn-red-white px-4 no-border-radius">
                          start now
                        </button>
                      </Link>

                      <p className="small cursor link-text mb-0 my-auto">
                        Learn more{" "}
                        <img
                          className="ml-3"
                          src={ArrowRight}
                          alt="arrow right"
                          style={{ maxWidth: "6px" }}
                        />
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4 my-auto py-md-0 py-4">
                <p
                  className="small text-white mb-0"
                  style={{ lineHeight: "22px" }}
                >
                  It is a popular belief that growing a business in this digital
                  age can be hard. That doesn’t have to be your truth. We are
                  here to change that notion by equipping your company with the
                  right answers to today’s business challenges, and to align
                  your business offerings with innovative technologies so that
                  you can do business in extraordinary ways.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container mb-5 pb-5 bg-help">
        <img
          src={BulbImage}
          alt="Idea"
          className="mx-auto d-flex mb-5 pb-3"
          style={{ maxWidth: "80px" }}
        />

        <h1 className="big-text text-center font-weight-bold mb-5 pb-5">
          We will <span className="text-red">focus</span> on your business and{" "}
          <span className="text-red">improve</span> your business performance in{" "}
          <span className="text-red">5 ways…</span>
        </h1>
      </div>

      <div className="container">
        <div className="my-5 py-5">
          {/* tab header */}
          <div className="d-inline-flex tab-header">
            <div
              className={
                "tabs cursor " + (activeTab === "process" ? "active" : "")
              }
              onClick={() => changeTab("process")}
            >
              <p className="mb-0">
                <span style={{ color: "#000" }}>01</span>{" "}
                {HomeData.tabs.process_transformation}
              </p>
            </div>

            <div
              className={
                "tabs cursor " + (activeTab === "profit" ? "active" : "")
              }
              onClick={() => changeTab("profit")}
            >
              <p className="mb-0">
                <span style={{ color: "#000" }}>02</span>{" "}
                {HomeData.tabs.maximazing_profit}
              </p>
            </div>

            <div
              className={
                "tabs cursor " + (activeTab === "speed" ? "active" : "")
              }
              onClick={() => changeTab("speed")}
            >
              <p className="mb-0">
                <span style={{ color: "#000" }}>03</span>{" "}
                {HomeData.tabs.speed_precision}
              </p>
            </div>

            <div
              className={
                "tabs cursor " + (activeTab === "risk" ? "active" : "")
              }
              onClick={() => changeTab("risk")}
            >
              <p className="mb-0">
                <span style={{ color: "#000" }}>04</span>{" "}
                {HomeData.tabs.risk_assessment}
              </p>
            </div>

            <div
              className={
                "tabs cursor " + (activeTab === "people" ? "active" : "")
              }
              onClick={() => changeTab("people")}
            >
              <p className="mb-0">
                <span style={{ color: "#000" }}>05</span>{" "}
                {HomeData.tabs.people_power}
              </p>
            </div>
          </div>

          {/* tab body */}
          <div className="py-md-5 mt-5 mb-md-5">
            {activeTab === "process" && <ProcessTransformation />}

            {activeTab === "profit" && <MaximizingProfit />}

            {activeTab === "speed" && <SpeedPrecision />}

            {activeTab === "risk" && <RiskAssessment />}

            {activeTab === "people" && <PeoplePower />}
          </div>
        </div>
      </div>

      <div className="container">
        <div className="net-income-bg mt-5 px-4 py-5 position-relative">
          <div className="px-4 mx-3">
            <div className="row">
              <div className="col-md-7">
                <h1
                  className="text-white font-weight-bold pb-3"
                  style={{ lineHeight: "48px" }}
                >
                  How much will your net income increase when we perfect your
                  business processes?
                </h1>

                <Link className="my-auto" to="/contact">
                  <button className="btn btn-red mt-3 mb-md-0 mb-4 btn-small btn-red-white px-4 text-uppercase no-border-radius d-md-block d-none">
                    Contact us today, let’s get started
                  </button>
                </Link>

                <Link className="my-auto" to="/contact">
                  <button className="btn btn-red mt-3 mb-md-0 mb-4 btn-small btn-red-white px-4 text-uppercase no-border-radius d-md-none d-block">
                    Contact us today
                  </button>
                </Link>
              </div>

              <div className="col-md-1"></div>

              <div className="col-md-4">
                <p
                  className="off-white"
                  style={{ lineHeight: "26px", fontSize: "15px" }}
                >
                  Let’s find out. We will equip your business with the right
                  technology, infrastructure, and know-how to transform your
                  operations across value chains and facilitate market
                  domination.
                </p>
              </div>
            </div>
          </div>

          <img src={NairaBg} alt="naira" className="naira-bg" />
        </div>
      </div>

      <div className="my-5 py-md-5 pb-3"></div>

      <div className="container my-5 py-5 bg-partners">
        <Partners />
      </div>

      <div className="my-md-5 py-md-5"></div>

      <div className="container my-5 pb-5 position-relative">
        <img className="team-number" src={TeamNumber} alt="team" />
        <Team />
      </div>

      <div className="my-md-5 py-md-5"></div>
      <div className="my-md-5 py-md-5"></div>

      <div className="my-5 py-md-5 bg-testimonial">
        <Testimonial />
      </div>

      <div className="mt-md-5 mb-md-5 py-md-5"></div>

      <Insight />

      <Modal show={show} onHide={() => setShow(false)} centered>
        <Modal.Body>
            <div className="text-right mb-1">
            <FaTimesCircle className="cursor" onClick={() => setShow(false)}  style={{color: '#fff', fontSize: '18px'}} />
            </div>
            <img src={SalahImage} alt="salah" className="img-fluid" />
        </Modal.Body>
      </Modal>
    </div>
  )
}

export default Home
