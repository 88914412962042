import { Link } from 'gatsby'
import React from 'react'
import ArrowRight from '../../../images/icons/arrow-right.svg'
import SpeedPrecisionOne from '../../../images/speed-precision/speed-precision-1.png'
import SpeedPrecisionTwo from '../../../images/speed-precision/speed-precision-2.png'
import SpeedPrecisionThree from '../../../images/speed-precision/speed-precision-3.png'

const SpeedPrecision = () => {
    return (
        <div className="bg-speed-precision py-5">

            <div className="row py-5">
                <div className="col-md-5">
                    <h2 className="mb-5 font-weight-bold" style={{ lineHeight: '40px' }}>Moving with lightning speed. Operating with pinpoint precision.</h2>

                    <p className="mb-3 text-secondary small" style={{ lineHeight: '26px', fontSize: '15px' }}>The future of business lies in the fusion and harmony of humans and bots to achieve intelligent automation in business processes.</p>

                    <p className="mb-3 text-secondary small" style={{ lineHeight: '26px', fontSize: '15px' }}>We are ready to future-proof your business by combining the prowess of intelligent technologies like Robotic Process Automation, Artificial Intelligence and Machine Learning to automate your business end-to-end processes so you can lead the market with an intelligent workforce.</p>

                    <div className="mt-4 d-inline-flex mb-md-0 mb-5">
                        <Link to={`/contact`} state={{ path: 'transformation' }}><button className="btn btn-red text-uppercase btn-red-black btn-small px-3 mr-4 no-border-radius" style={{ fontSize: '12px' }}>start now</button></Link>
                        <Link className="my-auto" to="/intelligent-automation-usecase"><p className="small cursor black-link-text mb-0 my-auto">Learn more <img className="ml-3" src={ArrowRight} alt="arrow right" style={{ maxWidth: '6px' }} /></p></Link>
                    </div>
                </div>

                <div className="col-md-1"></div>

                <div className="col-md-6 d-md-block d-none">
                    <div className="row">
                        <div className="col-md-6  mb-md-0 mb-4">
                            <div className="row">
                                <div className="col-md-12  mb-md-0 mb-4" >
                                    <img src={SpeedPrecisionOne} alt="speed precision" className="img-fluid" />
                                </div>

                                <div className="col-md-12 mt-4 pt-1">
                                    <img src={SpeedPrecisionThree} alt="speed precision" className="img-fluid" />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <img src={SpeedPrecisionTwo} alt="speed precision" className="img-fluid h-100" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default SpeedPrecision
