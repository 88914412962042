import React, { useState, useEffect } from 'react'
import SingleOpeningQuote from '../../../images/testimonial/opening-quote.svg'
import SingleClosingQuote from '../../../images/testimonial/closing-quote.svg'
import './testimonial.scss'
import TestimonialData from '../../../data/testimonial'
import { FaCaretRight, FaCaretLeft } from "react-icons/fa";

const Testimonial = () => {

    const [testimonials, setTestimonials] = useState([]);

    const [displayedTestimonial, setDisplayedTestimonial] = useState({});

    const [testimonialIndex, setTestimonialIndex] = useState(0)

    const nextTestimonial = () => {
        setTestimonialIndex(testimonialIndex + 1)
    }

    const prevTestimonial = () => {
        setTestimonialIndex(testimonialIndex - 1)
    }

    useEffect(() => {
        setTestimonials(TestimonialData)
    }, [])

    useEffect(() => {
        if (testimonials) {
            setDisplayedTestimonial(testimonials[testimonialIndex])
        }
    }, [testimonials, testimonialIndex])


    return (
        <div className="mt-5 testimonial pt-5">
            <h1 className="text-center font-weight-bold">Voices of our clients</h1>
            <p className="text-center" style={{fontSize: '1.25rem'}}>We have done it for businesses just like yours. Let us add you to the list.</p>

            {
                displayedTestimonial &&
                <div className="container">

                    <div className="row mt-5 pt-5">
                        <div className="col-md-2 my-auto d-md-block d-none">
                            {
                                testimonialIndex > 0 &&

                                <div className="testimonial-navigation cursor mx-auto d-flex justify-content-center align-items-center" onClick={() => prevTestimonial()}>
                                    <FaCaretLeft />
                                </div>
                            }

                        </div>

                        <div className="col-md-8 mx-auto text-center">

                            <div className="position-relative">
                                <img src={SingleOpeningQuote} className="opening-quote" alt="single quote" />
                                <img src={SingleClosingQuote} className="closing-quote" alt="single quote" />

                                <div>
                                    <img src={displayedTestimonial.image} alt="Chief" className="fitted-testimonial-img mb-4" />

                                    <h5 className="font-weight-bold">{displayedTestimonial.name}</h5>

                                    <p className="text-secondary">{displayedTestimonial.role}</p>


                                    <p className="mt-5" style={{fontSize: '1.1rem'}}>{displayedTestimonial.testimonial}</p>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-2 my-auto d-md-block d-none">
                            {testimonialIndex < testimonials.length - 1 &&
                                <div className="testimonial-navigation cursor mx-auto d-flex justify-content-center align-items-center" onClick={() => nextTestimonial()}>
                                    <FaCaretRight />
                                </div>
                            }
                        </div>

                        <div className="col-md-12 d-md-none d-block">
                            <div className="row">
                                <div className="col-6">
                                    <div className={`testimonial-navigation cursor ml-auto d-flex justify-content-center align-items-center ${testimonialIndex > 0 ? '' : 'disabled'}`} onClick={testimonialIndex > 0 ? () => prevTestimonial() : ''}>
                                        <FaCaretLeft />
                                    </div>
                                </div>

                                <div className="col-6">
                                    <div className={`testimonial-navigation cursor mr-auto d-flex justify-content-center align-items-center ${testimonialIndex < testimonials.length - 1 ? '' : 'disabled'}`} onClick={testimonialIndex < testimonials.length - 1 ? () => nextTestimonial() : ''}>
                                        <FaCaretRight />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}

export default Testimonial
