import React from 'react'
import './team.scss'
import ArrowRight from '../../../images/icons/arrow-right.svg'
import { FaLinkedinIn } from "react-icons/fa";
import { Link } from 'gatsby';

const Team = () => {
    return (
        <div className="container">
            <div className="team position-relative mb-5">
                <div className="teams-section my-5 py-5 px-4">
                    <div className="px-4 pb-5">
                        <div className="row">
                            <div className="col-md-5">
                                <h4 className="text-red font-weight-bold" style={{ lineHeight: '40px', fontSize: '2rem' }}>At the core of our business is a seasoned team of industry maestros.</h4>

                                <p className="py-3 text-secondary" style={{ lineHeight: '24px', fontSize: '15px' }}>We work with a powerful sense of TEAM SPIRIT, driven by a shared love of INNOVATION, upholding INTEGRITY to create IMPACT that screams EXCELLENCE.</p>

                                <Link to="/contact"><button className="btn btn-red btn-small btn-red-black px-4 no-border-radius text-uppercase">Get in touch with us</button></Link>
                            </div>

                            <div className="col-md-1"></div>

                            <div className="col-md-6">
                                <div className="row mt-md-0 mt-5 mb-md-0 mb-5 pb-md-0 pb-5">
                                    <div className="col-md-6 mb-md-0 mb-4 picture-in-front">
                                        <div className="team-bg-div bg-team-member-one">
                                            <div className="color-overlay d-flex align-items-end">
                                                <div className="p-3">
                                                    <h3 className="text-white font-weight-bold">Saidu Abdullahi</h3>
                                                    <p className="text-white small mb-1">CEO</p>

                                                    <a href="https://www.linkedin.com/in/saiduabdullahi" target="_blank"><FaLinkedinIn
                                                        color="fff"
                                                        className="cursor"
                                                    /></a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {/* <div className="col-md-6 mb-md-0 mb-5 picture-in-front">
                                        <div className="team-bg-div bg-team-member-two">
                                            <div className="color-overlay d-flex align-items-end">
                                                <div className="p-3">
                                                    <h3 className="text-white font-weight-bold">Simisola Ajiboye</h3>
                                                    <p className="text-white small mb-1">Excecutive Director</p>

                                                    <FaLinkedinIn
                                                        color="fff"
                                                        className="cursor"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="part-of-us p-3">
                    <p className="text-white font-weight-bold mb-2" style={{fontSize: '1.1rem'}}>Want to be a part of our quest?</p>

                    <p className="small text-white">We are always on the lookout for people driven by a need to impact lives and businesses positively.</p>

                    <p className="small cursor link-text">View open positions <img className="ml-3" src={ArrowRight} alt="arrow right" style={{ maxWidth: '6px' }} /></p>
                </div>
            </div>
        </div>
    )
}

export default Team
