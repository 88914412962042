const HomeData = {
    "improve_business": "We will focus on your business and improve your business performance in 5 ways…",
    "tabs": {
        "process_transformation": "Digital transformation",
        "maximazing_profit": "Maximizing Profit",
        "speed_precision": "Process automation",
        "risk_assessment": "Risk Assessment",
        "people_power": "Human resources"
    },
    "process_transformation": {
        "title": "Transforming your processes. Transcending your goals.",
        "sub_title": [
            "Emergence of new technologies has necessitated the need for digital everything which has caused a paradigm shift in what consumers want and how they want it.",
            "Give us the quest to lead your company on a seamless digital transformation journey, so that you can create new digital values and experiences that will align with the ever-changing needs of today’s consumers and ensure you stay relevant in the market."
        ]
    }
}

export default HomeData