import React from 'react'
import RiskOne from '../../../images/risk-assessement/risk-1.png'
import RiskTwo from '../../../images/risk-assessement/risk-2.png'
import RiskThree from '../../../images/risk-assessement/risk-3.png'
import ArrowRight from '../../../images/icons/arrow-right.svg'
import { Link } from 'gatsby'

const RiskAssessment = () => {
    return (
        <div className="bg-risk pt-5 pb-md-5">

            <div className="row pt-5 pb-md-5">
                <div className="col-md-5">
                    <h2 className="mb-5 font-weight-bold" style={{ lineHeight: '40px' }}>Defend against risks. Thrive with compliance</h2>

                    <p className="mb-3 text-secondary small" style={{ lineHeight: '26px', fontSize: '15px' }}>Businesses today are exposed to all kinds of unprecedented risks; some that will shake them to their very core, ones that will ruin their reputation, and others that will hinder financial growth.</p>

                    <p className="mb-3 text-secondary small" style={{ lineHeight: '26px', fontSize: '15px' }}>We are dedicated to ensuring your business is not one of those. We will take a consistent approach in identifying and accessing threats from all corners --  be it cyber, data, or infrastructure; building defences so your business can operate in a compliant, risk-free environment and foster a safe space for your customers.</p>

                    <div className="mt-4 d-inline-flex mb-md-0 mb-5">
                        <Link to={`/contact`} state={{ path: 'risk' }}><button className="btn btn-red text-uppercase btn-red-black btn-small px-3 mr-4 no-border-radius" style={{ fontSize: '12px' }}>start now</button></Link>
                        <Link className="my-auto"  to="/security-usecase"><p className="small cursor black-link-text mb-0 my-auto">Learn more <img className="ml-3" src={ArrowRight} alt="arrow right" style={{ maxWidth: '6px' }} /></p></Link>
                    </div>
                </div>

                <div className="col-md-1"></div>

                <div className="col-md-6 d-md-block d-none">
                    <div className="row">
                        <div className="col-md-6  mb-md-0 mb-4">
                            <img src={RiskOne} alt="speed precision" className="img-fluid h-100" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                        </div>

                        <div className="col-md-6">
                            <div className="row">
                                <div className="col-md-12  mb-md-0 mb-4">
                                    <img src={RiskTwo} alt="speed precision" className="img-fluid" />
                                </div>

                                <div className="col-md-12 mt-4 pt-1">
                                    <img src={RiskThree} alt="speed precision" className="img-fluid" />
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

        </div>
    )
}

export default RiskAssessment
