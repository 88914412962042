import DineshImg from '../images/testimonial/dinesh.jpeg'
import LindaImg from '../images/testimonial/linda.jpeg'

const TestimonialData = [
    {
        "image": DineshImg,
        "name": "Dinesh Mangaru",
        "role": "Senior IT Exec, CIO, CTO, Social Entrepreneur",
        "testimonial": "Saidu’s people leadership and engagement with stakeholders were key strengths during times of considerable organisational change, and lots of challenges."
    },

    {
        "image": LindaImg,
        "name": "Linda Quaynor",
        "role": "West Africa Strategy and Operations Leader, Deloitte",
        "testimonial": "The insights Saidu shared on the SMEs Operators panel discussion session were profound"
    }
]

export default TestimonialData