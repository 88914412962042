import { Link } from 'gatsby'
import React from 'react'
import ArrowRight from '../../../images/icons/arrow-right.svg'
import PeoplePowerOne from '../../../images/people-power/people-power-1.png'
import PeoplePowerTwo from '../../../images/people-power/people-power-2.png'
import PeoplePowerThree from '../../../images/people-power/people-power-3.png'

const PeoplePower = () => {
    return (
        <div className="bg-people-power pt-5 pb-md-5">

            <div className="row pt-5 pb-md-5">
                <div className="col-md-5">
                    <h2 className="mb-5 font-weight-bold" style={{ lineHeight: '40px' }}>Beyond IT services. Harnessing the amazing power of people</h2>

                    <p className="mb-3 text-secondary small" style={{ lineHeight: '26px', fontSize: '15px' }}>The power of any successful business lies in the people within. Sometimes, it goes beyond finding the right people to making any people the right people.</p>

                    <p className="mb-3 text-secondary small" style={{ lineHeight: '26px', fontSize: '15px' }}>We believe so much in the power of people and we know you do too. Let us work with you to build a team of formidable teams, reinforced with useful knowledge, technical know-how, new competencies and skills, so that they can in turn build up your business.</p>

                    <div className="mt-4 d-inline-flex mb-md-0 mb-5">
                        <Link to={`/contact`} state={{ path: 'human' }}><button className="btn btn-red text-uppercase btn-red-black btn-small px-3 mr-4 no-border-radius" style={{ fontSize: '12px' }}>start now</button></Link>
                        <Link className="my-auto" to="human-capital-development-usecase"><p className="small cursor black-link-text mb-0 my-auto">Learn more <img className="ml-3" src={ArrowRight} alt="arrow right" style={{ maxWidth: '6px' }} /></p></Link>
                    </div>
                </div>

                <div className="col-md-1"></div>

                <div className="col-md-6 d-md-block d-none">
                    <div className="row">
                        <div className="col-md-6  mb-md-0 mb-4">
                            <div className="row">
                                <div className="col-md-12">
                                    <img src={PeoplePowerOne} alt="speed precision" className="img-fluid" style={{ width: '100%', height: '100%', objectFit: 'contain' }} />
                                </div>

                                <div className="col-md-12 mt-4 pt-1">
                                    <img src={PeoplePowerThree} alt="speed precision" className="img-fluid" style={{ width: '100%', height: '100%', objectFit: 'contain' }} />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <img src={PeoplePowerTwo} alt="speed precision" className="img-fluid h-100" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default PeoplePower
